.brancheContent {
	padding-left: 20px;
	width: 100%;
}

.labelInput {
	margin-bottom: 10px;
}

.windowBox {
	margin-bottom: 10px;
	width: 100%;
}

.rentalInsurranceWarning {
	color: darkorange;
}