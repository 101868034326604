.root {
	width: 100%;
	height: 100%;
	padding: 1em;
}

.rootOverflowXauto {
	overflow-x: auto;
}

.rootOverflowXnone {
	overflow-x: hidden;
}

.rootOverflowYauto {
	overflow-y: auto;
}

.rootOverflowYnone {
	overflow-y: hidden;
}