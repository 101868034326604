.closeDiv {
	position: absolute;
	right: 0px;
	top: 0px;
}

.header {
	position: relative;
	width: 100%;
}

.closeButton {
	height: 25px;
	width: 25px;
}

.collapseButtonSpan {
	text-align: right;
	float: right;
}