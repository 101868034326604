.content {
	text-align: left;
}

.buttonsDiv {
	text-align: right;
	margin-right: auto;
}

.modal {
	width: 100%;
	height: 100%;
}

@media (min-width: 420px) {
	.modal {
		width: auto;
		min-width: 400px;
		height: auto;
	}
}