.root {
	height: 100%;
	width: 100%;
}

.grid {
	display: grid;
	grid-template-columns: auto 1fr;
	height: 100%;
	width: 100%;
}

.verticalMenuDiv {
	overflow: hidden;
	padding: 1em;
	min-width: 300px;
}

.horizontalMenuDiv {
	display: none;
}

@media(max-width: 800px) {
	.grid {
		grid-template-columns: 1fr;
	}
	.verticalMenuDiv {
		display: none;
	}
	.horizontalMenuDiv {
		display: block;
	}
}

.contentDiv {
	overflow: hidden;
}

.dropDownMenuDiv {
	margin-bottom: 20px;
}

.windowBox {
	margin-bottom: 20px;
	width: 100%;
}

.menuItem {
	margin-bottom: 10px;
}