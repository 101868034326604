.table {
	color: #1E3A6F;
	border-spacing: 0px;
}

/* Rechtse cell in 1ste rij */
.table tbody tr:first-child>td:first-child {
	border-top-left-radius: 4px;
}

/* Linkse cell in 1ste rij */
.table tbody tr:first-child>td:last-child {
	border-top-right-radius: 4px;
}

.table tbody tr {
	background-color: white;
}

/* Iedere cell */
.gridCell {
	padding: 10px;
	border-width: 1px 1px 0px 0px;
	border-color: #E8ECF1;
	border-style: solid;
}

/* Eerst cell in rij */
.table tbody tr td:first-child {
	border-left-width: 1px;
}

/* Onderste cellen */
.table tbody tr:last-child>td {
	border-bottom-width: 1px;
}

.table tbody tr td.heavy {
	font-weight: bold;
}

/* Meest rechter cell in de laatste rij */
.table tbody tr:last-child>td:first-child {
	border-bottom-left-radius: 4px;
}

/* Meest linker cell in de laatste rij */
.table tbody tr:last-child>td:last-child {
	border-bottom-right-radius: 4px;
}

.button_cell {
	cursor: pointer;
}

/* Licht groen */
.light_green_cell {
	background-color: #6BD49E;
}

.light_green_cell_button:hover {
	background-color: #60BC8C;
}

.light_green_cell_button:active {
	background-color: #58AA7F;
}

/* Donkerblauw cell */
.dark_blue_cell {
	background-color: #1E3A6F;
	color: white;
}

.dark_blue_cell_button:hover {
	background-color: #1A355E;
}

.dark_blue_cell_button:active {
	background-color: #11243F;
}

/* Grijze cell */
.grey_cell {
	background-color: #D1D8E2;
}

.grey_cell_button:hover {
	background-color: #BDC3CC;
}

.grey_cell_button:active {
	background-color: #A6ABB2;
}

/* Rode cell */
.red_cell {
	background-color: #ED2035;
	color: white;
}

.red_cell_button:hover {
	background-color: #D81E34;
}

.red_cell_button:active {
	background-color: #C61B32;
}

/* Witte cell */
.white_cell {
	background-color: white;
}

.white_cell_button:hover {
	background-color: #FAFAFA;
}

.white_cell_button:active {
	background-color: #EFEFEF;
}

/* Oranje cell */
.orange_cell {
	background-color: #FF6A00;
	color: white;
}

.orange_cell_button:hover {
	background-color: #E05900;
}

.orange_cell_button:active {
	background-color: #BC4800;
}