.table {
	max-width: 100%;
	display: grid;
	color: #1E3A6F;
}

/* Iedere laatste cell in iedere regel */
.table>.row>.cell:last-child,
.table>.headerRow>.headerCell:last-child {
	padding-right: 10px;
}

/* Header regel */
.table>:first-child>.headerCell {
	background-color: #D1D8E2;
	font-weight: bold;
}

.table>:first-child>.headerCell.heavyCell {
	background-color: #D1D8E2;
	font-weight: bold;
}

.table>:first-child>.headerCell.lightCell {
	background-color: #D1D8E2;
	font-weight: normal;
}

.table>.headerDark:first-child>.headerCell {
	background-color: #D1D8E2;
}

.table>.headerLight:first-child>.headerCell {
	background-color: #E8ECF1;
}

/* Cell links-boven */
.table>:first-child>.headerCell:first-child,
.table>:first-child>.cell:first-child {
	border-top-left-radius: 4px;
}

/* Cell rights-boven */
.table>:first-child>.headerCell:last-child,
.table>:first-child>.cell:last-child {
	border-top-right-radius: 4px;
	padding: 10px;
}

/* Cell links-onder */
.table>:last-child>.cell:first-child,
.table>:last-child>.headerCell:first-child {
	border-bottom-left-radius: 4px;
}

/* Cell rechts-onder */
.table>:last-child>.cell:last-child,
.table>:last-child>.headerCell:last-child {
	border-bottom-right-radius: 4px;
}

.row,
.headerRow {
	display: contents;
}

.cell,
.headerCell {
	background-color: white;
	text-align: left;
	padding: 10px 0px 10px 10px;
	
	/* Cell zo klein mogelijk */
	word-wrap: break-word;
	white-space: normal;
	min-width: 0;
}

.cell.nowrap,
.headerCell.nowrap,
.nowrap .cell,
.nowrap .headerCell {
	word-wrap: none;
	white-space: nowrap;
	min-width: inherit;
}

.heavyCell {
	font-weight: bold;
}

.lightCell {
	font-weight: normal;
}

.clickableRow:hover>.cell {
	background-color: #3667C1;
	color: white;
	cursor: pointer;
}

.clickableRow:active>.cell {
	background-color: #1E3A6F;
	color: white;
	cursor: pointer;
}

.clickableHeaderCell:hover {
	cursor: pointer;
}

.clickableHeaderCell:active {
}