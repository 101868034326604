.root {
	width: 100%;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.content {
	text-align: left;
}

.toolbar {
}

.userBlock {
	margin-top: 20px;
	text-align: left;
}

.rightsBlock {
	margin-top: 20px;
}

.label {
	display: block;
	font-weight: bold;
	margin-top: 10px;
}

.saveButtonSpan {
	display: block;
	margin-top: 30px;
	width: 100%;
	text-align: center;
}

.passwordDiv {
	margin-top: 20px;
}

.deleteButtonSpan {
	display: block;
	margin-top: 30px;
	width: 100%;
	text-align: center;
}

.deleteBlock {
	margin-top: 30px;
	text-align: center;
}

.inputField {
	margin-top: 10px;
	width: 100%;
}