.container {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 25px;
	padding-top: 3px;
  }

  /* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }

  /* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #FFFFFF;
	border-radius: .28571429rem;
	border: 1px solid rgba(34,36,38,.15);
  }

  /* Checkbox mouse over */
.container:hover input ~ .checkmark {
  }
  
  /* Checkbox checked */
  .container input:checked ~ .checkmark {
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
	left: 9px;
	top: 5px;
	width: 7px;
	height: 14px;
	border: solid #2196F3;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }