.root {
}

.root:after {
	content: "";
	display: table;
	clear: both;
}

.column {
	float: left;
}