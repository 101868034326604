.root {
	border: 0px SOLID black;
	border-radius: 4px;
	width: 100%;
	background-color: white;
	padding-left: 4px;
	padding-right: 4px;
}

.root>div {
	width: auto;
	height: 100%;
	display: inline-block;
	padding: 15px;
	margin: 0px;
	border-right: 2px SOLID #e8ecf1;
}

.toolbarButton {
	vertical-align: middle;
}

.toolbarButton:hover {
	background-color: #efefef;
	cursor: pointer;
}

.toolbarButton:active {
	background-color: #cdcdcd;
}

.toolbarDropdown {
}

.toolbarDropdown:hover {
	background-color: #efefef;
	cursor: pointer;
}

.toolbarDropdown:active {
	background-color: #cdcdcd;
}

.toolbarDropdownMenu {
	display: block;
	position: absolute;
	background-color: white;
	border: 1px SOLID #96c8da;
	-webkit-box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
	border-radius: .28571429rem;

	padding-top: .28571429rem;
	padding-bottom: .28571429rem;
	padding-left: 0px;
	padding-right: 0px;
}

.toolbarDropdownMenu>ul {
	margin: 0px;
	padding: 0px;
}

.toolbarDropdownMenu>ul>li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	padding: 5px 10px 5px 10px;
}

.toolbarDropdownMenu>ul>li:hover {
	background-color: #efefef;
	cursor: pointer;
}

.toolbarDropdownMenu>ul>li:active {
	background-color: #cdcdcd;
}

.currentDropdownValue {
	font-weight: bold;
}

.toolbarButtonImg {
	vertical-align: middle;
	height: 2em;
}

.toolbarButtonImgWithCaption {
	margin-right: 10px;
}