.passwordBlock {
	text-align: left;
}

.changePasswordButtonSpan {
	display: block;
	margin-top: 30px;
	width: 100%;
	text-align: center;
}

.label {
	display: block;
	font-weight: bold;
	margin-top: 10px;
}