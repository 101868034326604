.root {
	position: fixed;
	display: block;
	z-index: 505;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
	height: 100%;
	width: 100%;
}

.backgroundPanel {
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	height: 100%;
	width: 100%;

	background-color: rgba(0,0,0,.4);
}

.messageBoxPanel {
	display: flex;

	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;

	position: absolute;
	top: 0;
	left: auto;
	bottom: 0;
	right: 0;
	
	width: 100%;
	height: 100%;
	
}

.messageBox {
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;

	background-color: #D1D8E2;
	color: #203A6E;

	border-radius: 5px;
	position: relative;
}

.header {
	font-size: 14pt;
	word-wrap: none;
}

.closeButton {
	-webkit-font-smoothing: antialiased;
	font-family: Calibri, Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: 400;
    box-sizing: border-box;
    display: block;
    text-align: center;
    cursor: pointer;
	
	padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
	padding-left: 4px;
	float: right;
	top: 0px;
	
    min-width: 32px;
    height: 44px;
    background-color: transparent;
    color: rgb(96, 94, 92);
    width: 44px;
    user-select: none;
    outline: transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
}

.floatReset {
	clear: both;
}

.closeButton:hover {
	color: rgb(50, 49, 48);
    background-color: rgb(243, 242, 241);
}

.messageBoxContent {
	padding: 30px;
	text-align: center;
}

.loaderDiv {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	backdrop-filter: blur(5px);
}

.loaderCenterDiv {
	margin-top: 40%;
	margin-left: auto;
	height: 100%;
	vertical-align: middle;
	text-align: center;
}

.loaderSpinner {
	color: official;
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

  .loaderSpinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}

.loaderSpinner div:after {
	content: " ";
	display: block;
	position: absolute;
	top: 3px;
	left: 37px;
	width: 6px;
	height: 18px;
	border-radius: 20%;
	background: #000000;
  }

  .loaderSpinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
  }

  .loaderSpinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
  }

  .loaderSpinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
  }
  .loaderSpinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
  }
  .loaderSpinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
  }
  .loaderSpinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
  }
  .loaderSpinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
  }
  .loaderSpinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
  }
  .loaderSpinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
  }
  .loaderSpinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
  }
  .loaderSpinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
  }
  .loaderSpinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
  }
  @keyframes lds-spinner {
	0% {
	  opacity: 1;
	}
	100% {
	  opacity: 0;
	}
  }
  