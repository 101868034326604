/*
	Bij toevoegen nieuwe kleur, toevoegen in:
	BackgroundColors.module.css => de standaard achtergrondkleur
	ButtonBackgroundColors.module.css => achtergrondkleur bij hover en active (knoppen, etc)
	BackgroundColor.tsx => de kleuren enum
*/
.green {
	background-color: #21BA45;
	color: white;
}

.orange {
	background-color: #F2711C;
	color: white;
}

.red {
	background-color: #DB2828;
	color: white;
}

.yellow {
	background-color: #fbbd08;
	color: white;
}

.blue {
	background-color: #2185d0;
	color: white;
}

.black {
	background-color: #1b1c1d;
	color: white;
}

.grey {
	background-color: #767676;
}

.lightGrey {
	background-color: #D1D8E2;
}

.white {
	background-color: white;
}

.olive {
	background-color: #b5cc18;
	color: #ffffff;
}

.teal {
	background-color: #00b5ad;
	color: #ffffff;
}

.violet {
	background-color: #6435c9;
	color: #ffffff;
}

.purple {
	background-color: #a333c8;
	color: #ffffff;
}

.pink {
	background-color: #e03997;
	color: #ffffff;
}

.brown {
	background-color: #a5673f;
	color: #ffffff;
}