.root {
	position: absolute;
	display: block;
	z-index: 500;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
	height: 100%;
	width: 100%;
}

.backgroundPanel {
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	height: 100%;
	width: 100%;

	background-color: rgba(0,0,0,.4);
}

.panel {
	display: flex;

	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;

	position: absolute;
	top: 0;
	left: auto;
	bottom: 0;
	right: 0;
	
	width: 100%;
	height: 100%;
	
	box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
	background-color: #D1D8E2;
}

@media (min-width: 480px) {
	.panel {
		width: auto;
		min-width: 340px;
		max-width: 100%;
	}

	.panel10 {
		width: 10%;
	}

	.panel20 {
		width: 20%;
	}

	.panel30 {
		width: 30%;
	}

	.panel40 {
		width: 40%;
	}

	.panel50 {
		width: 50%;
	}

	.panel60 {
		width: 60%;
	}

	.panel70 {
		width: 70%;
	}

	.panel80 {
		width: 80%;
	}

	.panel90 {
		width: 90%;
	}

	.panel100 {
		width: 100%;
	}
}

.closeButton {
	position: absolute;
	left: auto;
	right: 0;
	top: 0;
	-webkit-font-smoothing: antialiased;
	font-family: Calibri, Verdana, Geneva, Tahoma, sans-serif;
    font-size: 20px;
    font-weight: 400;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
	
	padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
	padding-left: 4px;
	
    min-width: 32px;
    height: 44px;
    background-color: transparent;
    color: rgb(96, 94, 92);
    width: 44px;
    user-select: none;
    outline: transparent;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    text-decoration: none;
    border-radius: 2px;
}

.closeButton:hover {
	color: rgb(50, 49, 48);
    background-color: rgb(243, 242, 241);
}

.headerCaption {
	font-size: 16px;
	color: #1E3A6F;
	
	margin-left: 10px;
	line-height: 44px;
	height: 44px;
}

.content {
	height: 100%;
	width: 100%;
	padding: 10px;
	overflow-y: auto;
	overflow-x: hidden;
}