.labelMax {
	width: 100%;
	display: block;
}

.labelAuto {
	width: auto;
	display: inline;
}

.labelContent {
	font-weight: bold;
	display: block;
}

.inputMax {
	width: 100%
}

.inputAuto {
	width: auto;
}

.formGroup {
	margin-bottom: 15px;
}