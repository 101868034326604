.rootDiv {
	width: 100%;
	padding: 0px;
}

.headerRow {
	padding: 0px;
	margin: 0px;
	border: 0px;
	display: flex;
	height: 66px;
}

.headerRow > :first-child {
	border-top-left-radius: 4px;
}

.headerRow > :last-child {
	border-top-right-radius: 4px;
}

.growToEnd {
	flex-grow: 1;
	background-color: white;
}

.content {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	padding: 10px;
}

.light .content {
	background-color: #e8ecf1;
}

.dark .content {
	background-color: #D1D8E2;
}

.tabHeader {
	margin: 0px;
	padding: 0px;
	background-color: white;
	border-right: 1px SOLID #e8ecf1;
	text-align: center;
	cursor: pointer;
}

.tabHeader:hover {
	background-color: #FAFAFA;
}

.light .tabHeaderActive {
	background-color: #e8ecf1;
}

.dark .tabHeaderActive {
	background-color: #D1D8E2;
}

.light .tabHeaderActive:hover {
	background-color: #e8ecf1;
}

.dark .tabHeaderActive:hover {
	background-color: #b2c6e2;
}

.caption {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	color: #1e3a6f;
}



