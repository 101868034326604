.numPadButton {
	padding: 30px;
}

.numPadInputBox {
	padding: 5px;
}

.svgButtonImage {
	width: 11pt;
	height: 11pt;
}

.svgCoinImage {
	width: 40pt;
	height: 40pt;
}

.svgBanknoteImage {
	width: 80pt;
}