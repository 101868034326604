/*
	Bij toevoegen nieuwe kleur, toevoegen in:
	BackgroundColors.module.css => de standaard achtergrondkleur
	ButtonBackgroundColors.module.css => achtergrondkleur bij hover en active (knoppen, etc)
	BackgroundColor.tsx => de kleuren enum
*/

/* Groen */
.green:hover {
	background-color: #16AB39;
}

.green:active {
	background-color: #198F35;
}

/* Oranje cell */
.orange:hover {
	background-color: #F26202;
}

.orange:active {
	background-color: #CF590C;
}

/* Rode cell */
.red:hover {
	background-color: #D01919;
}

.red:active {
	background-color: #B21E1E;
}

/* Geel cell */
.yellow:hover {
	background-color: #eaae00;
}

.yellow:active {
	background-color: #cd9903;
}

/* Donkerblauw cell */
.blue:hover {
	background-color: #1678c2;
}

.blue:active {
	background-color: #1a69a4;
}

/* Zwarte knop */
.black:hover {
	background-color: #27292a;
}

.black:active {
	background-color: #343637;
}

/* Grijze cell */
.grey:hover {
	background-color: #838383;
}

.grey:active {
	background-color: #909090;
}

/* Licht grijs */
.lightGrey:hover {
	background-color: #B8BEC6;
}

.lightGrey:active {
	background-color: #A3A8AF;
}

/* Witte cell (niet semantic) */
.white:hover {
	background-color: #FAFAFA;
}

.white:active {
	background-color: #EFEFEF;
}

/* Olijf */
.olive:hover {
	background-color: #a7bd0d;
}

.olive:active {
	background-color: #8d9e13;
}

/* Teal */
.teal:hover {
	background-color: #009c95;
}

.teal:active {
	background-color: #00827c;
}

/* Violet */
.violet:hover {
	background-color: #5829bb;
}

.violet:active {
	background-color: #502aa1;
}

/* Purple */
.purple:hover {
	background-color: #9627ba;
}

.purple:active {
	background-color: #82299f;
}

/* Pink */
.pink:hover {
	background-color: #e61a8d;
}

.pink:active {
	background-color: #c71f7e;
}

/* Brown */
.brown:hover {
	background-color: #975b33;
}

.brown:active {
	background-color: #805031;
}