html,
body,
.reactContent {
	margin: 0px 0px 0px 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	font-family: 'MarkPro', Roboto, Calabri, Verdana, Geneva, Tahoma, 'Open Sans', sans-serif;
	font-size: 11pt;
	position: fixed;
	background-color: #F2F7FF;
	color: #000000;
}

::-webkit-scrollbar {
	width: 7px !important;
	background: #ffffff !important;
}

::-webkit-scrollbar-thumb {
	border-radius: 5px !important;
	background: #7D7D7D !important;
}

::-webkit-scrollbar-thumb:window-inactive {
	background: #C7C7C7 !important;
}

.rbHeader {
	background: #28558E;
	color: white;
}

.ui.secondary.menu .item {
	border-radius: 0 !important;
	padding-top: 0.928571em !important;
	padding-bottom: 0.928571em !important;
}

.ui.secondary.menu .active.item {
	border-radius: 0 !important;
	padding-top: 0.928571em !important;
	padding-bottom: 0.928571em !important;
}

.ui.secondary.vertical.menu > .item {
	border-radius: 0 !important;
	padding-top: 0.928571em !important;
	padding-bottom: 0.928571em !important;
}

.ui.secondary.menu .active.item {
	border-radius: 0 !important;
	padding-top: 0.928571em !important;
	padding-bottom: 0.928571em !important;
}

h1 {
	font-size: 16px;
}

h2 {
	color: #C61D40;
	font-size: 13pt;
}

h3 {
	color: orange;
	font-size: 11pt;
}

.ui.table thead th {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.ui.table td {
	padding-top: 2px !important;
	padding-bottom: 2px !important;
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.table {
	display: table;
}

.row {
	display: table-row;
}

.cell {
	display: table-cell;
}

.shadow {
	box-shadow: 0px 0px 25px 0px rgba(0,0,0,.05);
}

/* Geen header bij tabellen in mobiel */
@media only screen and (max-width: 767px) {
	.noHeaderWhenMobile {
		display: none !important;
	}
}
