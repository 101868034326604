.rootLabel {
}

.content {
	width: 100%;
}

.caption {
	float: left;
	font-weight: bold;
}

.value {
	float: right;
	font-weight: normal;
}

.clear {
	clear: both;
}