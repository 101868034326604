.table { 
	display: table; 
}

.row { 
	display: table-row; 
}

.cell { 
	display: table-cell; 
}