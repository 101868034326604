.menuButton {
	color: #0f1d38;
	padding: 0 10px;
}

.menuLinkButton {
	border-bottom: 3px solid transparent;
	height: 58px;
	vertical-align: middle;
	display: inline-flex;
	align-items: center;
	justify-items: center;
	line-height: 1.2;
}

.menuButtonActive > * {
	border-color: black !important;
}