.message {
	font-size: 1em;
	position: relative;
    min-height: 1em;
    margin: 1em 0;
    background: #f8f8f9;
    padding: 1em 1.5em;
    line-height: 1.4285em;
    color: rgba(0,0,0,.87);
    -webkit-transition: opacity .1s ease,color .1s ease,background .1s ease,-webkit-box-shadow .1s ease;
    transition: opacity .1s ease,color .1s ease,background .1s ease,-webkit-box-shadow .1s ease;
    transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease;
    transition: opacity .1s ease,color .1s ease,background .1s ease,box-shadow .1s ease,-webkit-box-shadow .1s ease;
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px rgba(34,36,38,.22) inset, 0 0 0 0 transparent;
}

.error {
	background-color: #fff6f6;
	color: #9f3a38;
	-webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
}

.warning {
	background-color: #fffaf3;
    color: #573a08;
	webkit-box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
}

.info {
	background-color: #f8ffff;
	color: #276f86;
	-webkit-box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent;
}