.header {
	display: flex;
	flex-flow: nowrap;
	justify-content: space-between;
	align-items: center;
  }
  
  .header span {
	padding: 1em;
	color: #C61D40;
  }
  
  .header::before, 
  .header::after {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	content: " ";
	height: 0px;
	border-bottom: 2px solid #C61D40;
  }