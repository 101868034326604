.organisationContent {
	padding-left: 20px;
	width: 100%;
}

.gridRow {
	display: block;
}

.gridCell {
	display: inline;
}

.labelContent {
	font-weight: bold;
	display: block;
}