.roundButton {
	background-color: #e8ecf1;
	border-radius: 50%;
	height: 38px;
	width: 38px;
	padding: 0px;
	text-align: center;
	vertical-align: middle
}

.roundButtonActive {
	background-color: #1e3a6f;
	color: white;
}

.img {
	height: 40%;
	width: 40%;
	margin: auto;
	fill: white;
	vertical-align: middle;
}

.icon {
	font-size: 10px;
	margin: auto;
	vertical-align: middle;
	color: #243a6b;
}